import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Row, Container, Image, Navbar, Button, Col } from 'react-bootstrap';
import ToonCard from './components/ToonCard'
import { Discord } from 'react-bootstrap-icons';

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            loggedIn: false,
            user: null,
            toons: [],
        };
    }

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser() {
        fetch('/api/user')
        .then(res => res.json())
        .then(
            (result) => {
                document.body.className = '';
                this.setState({
                    isLoaded: true,
                    loggedIn: true,
                    user: result,
                });
                this.fetchToons();
            },
            (error) => {
                document.body.className = 'signin-body text-center';
                this.setState({
                    isLoaded: true,
                });
            }
        )
    }

    fetchToons() {
        fetch('/api/toon')
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    toons: result,
                })
            },
            (error) => {
                this.setState({
                    toons: [],
                })
            }
        )
    }

    Navbar = () => {
        const { loggedIn, user } = this.state;
        return (
            <Navbar variant='dark' bg='dark' className='shadow-sm'>
                <Container>
                    <Navbar.Brand><strong>Toon Viewer</strong></Navbar.Brand>
                    {loggedIn &&
                        <Image roundedCircle width='48px' height='48px' src={user.avatar_url}/>
                    }
                </Container>
            </Navbar>
        );
    }

    Login = () => {
        return (
            <main className='signin-main'>
                <div>
                <h1 className='h3 mb-3 fw-normal'>Sign In With Discord</h1>
                <Button as='a' className='w-100 btn btn-lg btn-primary' href='/login'><Discord/></Button>
                </div>
                
            </main>
        );
    }

    render() {
        const { isLoaded, loggedIn,toons } = this.state;
        if (isLoaded) {
            if (loggedIn) {
                return (
                    <React.Fragment>
                        <this.Navbar/>

                        {toons.length === 0 ? (
                            <Container as='section' className='py-5 text-center'>
                                <Row className='py-lg-5'>
                                    <Col className='mx-auto' md='8' lg='6'>
                                        <h1 className='fw-light'>Quiet around here...</h1>
                                        <p className='lead text-muted'>You need to link your <em>Toontown Rewritten</em> account before you can view your Toons. Click the button below to get started!</p>
                                        <p>
                                            <Button as='a' href='/link' variant='primary'>Link your account</Button>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        ) : (
                            <Container className='py-5' bg='light'>
                                <Row xs='1' sm='2' md='3' className='g-3'>
                                    {toons.map((toon, i) => (
                                        <ToonCard toon={toon} key={toon.id}/>
                                    ))}
                                </Row>
                            </Container>
                        )}
                    </React.Fragment>
                );
            } else {
                return <this.Login/>
            }
        } else {
            return '';
        }
    }
}

function App() {
    return (
        <Main/>
    );
}

export default App;
