import React from 'react';
import { Card, Col, DropdownButton, Dropdown } from 'react-bootstrap';

const poseToFriendly = {
    'portrait':             'Portrait - Standard',
    'portrait-delighted':   'Portrait - Delighted',
    'portrait-sleep':       'Portrait - Sleeping',
    'portrait-surprise':    'Portrait - Surprised',
    'portrait-thinking':    'Portrait - Thinking',
    'crying':               'Scene - Crying',
    'waving':               'Scene - Waving',
    'head':                 'Head',
};

export default class ToonCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pose: 'portrait',
            size: 512,
        };
    }

    onPoseSelected = (pose) => {
        this.setState({
            pose: pose,
        });
    }

    PoseSelect = () => {
        return (
            <DropdownButton variant='secondary' title='Select A Pose' onSelect={this.onPoseSelected}>
                {Object.keys(poseToFriendly).map((pose) => (
                    <Dropdown.Item key={pose} eventKey={pose}>{poseToFriendly[pose]}</Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }

    render() {
        const { toon } = this.props;
        const { pose, size } = this.state;
        return (
            <Col>
                <Card className='shadow-sm'>
                <Card.Header className='text-center' as='h5'>{toon.name}</Card.Header>
                <Card.Img variant='top' src={`/render/${toon.id}/${pose}/${size}`}/>
                <Card.Footer>
                    <div className='d-flex justify-content-between align-items-center'>
                        <this.PoseSelect/>
                        <small className='text-muted'>{poseToFriendly[pose]}</small>
                    </div>
                </Card.Footer>
                </Card>
            </Col>
        );
    }
}